.log-in-child {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f4f8fc;
  width: 668px;
  height: 900px;
}
.welcome-to-market-container {
  position: relative;
  line-height: 26px;
}
.get-started,
.log-in1 {
  position: relative;
}
.log-in1 {
  font-size: 18px;
}
.get-started-parent,
.welcome-to-market-minder-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.get-started-parent {
  gap: 4px;
  font-family: var(--font-open-sans);
}
.welcome-to-market-minder-parent {
  gap: 60px;
}
.group-child-login {
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.vector-icon {
  position: relative;
  width: 20px;
  height: 20px;
}
.user-id {
  position: relative;
  line-height: 20px;
}
.vector-parent {
  position: absolute;
  top: 14px;
  left: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frame-child-login,
.rectangle-parent-login {
  position: relative;
  width: 488px;
}
.frame-child-login {
  width: 27.5px;
  height: 21.5px;
}
.password {
  position: absolute;
  top: 0;
  left: 38px;
  line-height: 20px;
}
.frame-item {
  position: relative;
  width: 17.5px;
  height: 21.5px;
}

.password-parent {
  position: absolute;
  top: 14px;
  left: 16px;
  width: 111px;
  height: 20px;
}
.frame-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.stafflinepro {
  font-weight: 600;
  font-family: var(--font-open-sans);
}
.terms-of-services {
  text-decoration: underline;
  color: var(--color-darkslateblue);
}
.by-clicking-sign-up-you-agree {
  margin: 0;
}
.and {
  color: var(--color-darkslategray);
}
.privacy-policy {
  text-decoration: underline;
}
.and-privacy-policy {
  margin: 0;
  color: var(--color-darkslateblue);
}
.by-clicking-sign-container {
  position: relative;
  font-size: 14px;
}
.frame-container-login {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.rectangle-wrapper {
  position: relative;
  width: 488px;
  height: 1px;
}
.dont-have-an,
.sign-up {
  position: relative;
  font-weight: 600;
}
.sign-up {
  text-decoration: none;
  font-weight: 700;
  color: var(--color-darkslateblue);
}
.dont-have-an-account-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: var(--font-size-3xl);
}
.frame-group-login {
  align-items: center;
  gap: var(--gap-21xl);
}
.frame-group-login,
.frame-parent,
.frame-wrapper-login {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-wrapper-login {
  align-items: center;
  font-size: var(--font-size-base);
  font-family: var(--font-open-sans);
}
.frame-parent {
  position: absolute;
  top: calc(50% - 305px);
  left: 736px;
  align-items: flex-start;
  gap: var(--gap-13xl);
}
.minder {
  font-weight: 300;
  color: #505050;
}
.lorem-ipsum-dolor-login {
  margin: 0;
  padding-left: var(--padding-2xl);
}
.lorem-ipsum-dolor-login-container {
  position: relative;
  display: inline-block;
  width: 446px;
  flex-shrink: 0;
}
.lorem-ipsum-dolor-login-sit-amet-co-wrapper {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frame-parent2-login,
.nam-in-bibendum-lacus-class-a-wrapper,
.sed-convallis-congue-tristique-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent2-login,
.sed-convallis-congue-tristique-wrapper {
  align-items: center;
}
.frame-parent2-login {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xs);
  font-size: var(--font-inherit);
  color: var(--color-darkslategray);
  font-family: var(--font-inherit);
}
.group-icon-login,
.group-icon1-login,
.group-icon2-login {
  position: absolute;
  height: 80.51%;
  width: 38.38%;
  top: 0;
  right: 0;
  bottom: 19.49%;
  left: 61.62%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.group-icon1-login,
.group-icon2-login {
  height: 99.13%;
  width: 73.43%;
  top: 0.87%;
  right: 26.57%;
  bottom: 0;
  left: 0;
}
.group-icon2-login {
  height: 29.11%;
  width: 21.14%;
  top: 6.15%;
  right: 43.55%;
  bottom: 64.74%;
  left: 35.3%;
}
.group-group {
  position: relative;
  width: 488px;
  height: 420.13px;
}
.frame-parent1 {
  position: absolute;
  top: 89px;
  left: 112px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  font-size: 44px;
  color: var(--color-darkslateblue);
}
.log-in {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 900px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-9xl);
  color: var(--color-darkslategray);
  font-family: var(--font-source-sans-pro);
}

.login-btn {
  width: 100%;
  border-radius: 6px;
  background: #0257ab;
}

.loginImage {
  position: absolute;
  right: 108px;
  top: -55px;
}