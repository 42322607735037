.custom-colors .MuiSwitch-thumb {
  color: red;
}
.custom-colors .MuiButtonBase-root.MuiSwitch-switchBase {
  background-color: transparent;
}
.custom-colors .Mui-checked + .MuiSwitch-track {
  background-color: transparent !important;
  border: 2px solid #2aca7d;
}
.custom-colors .MuiSwitch-track {
  background-color: transparent !important;
  border: 2px solid red;
}

.custom-colors .Mui-checked .MuiSwitch-thumb {
  background-color: #2aca7d !important;
}

.switch-v2 {
  border: solid;
  border-color: #f81919;
}

.switch-v2 .MuiSwitch-thumb {
  color: #f81919;
}
.switch-v2 .MuiSwitch-track {
  background-color: white;
}

.switch-v2-checked {
  border: solid;
  border-color: #2aca7d;
}

.switch-v2-checked .Mui-checked .MuiSwitch-thumb {
  background-color: #2aca7d !important;
}
.switch-v2-checked .MuiSwitch-track {
  background-color: white !important;
}

.switch-v2-small {
  width: 29px !important;
  height: 10px !important;
}

.switch-v2-small .MuiSwitch-thumb {
  width: 9px !important;
  height: 9px !important;
}
.switch-v2-small .MuiSwitch-switchBase {
  transform: translateX(-5px) !important;
}
.switch-v2-small .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(5px) !important;
}

.MuiSwitch-root {
  padding: 0 !important;
  width: 42px !important; /* Width of Toggle button */
  height: 21px !important; /* Height of Toggle button */
  border-radius: 30px !important;
}
.MuiSwitch-root .Mui-checked + .MuiSwitch-track {
  opacity: 2 !important;
}
.MuiSwitch-root .MuiSwitch-switchBase {
  padding: 5px !important;
  width: 140% !important; /* Inner width of circle when disabled*/
  height: 100% !important;
  transform: translateX(-18px) !important;
  &.Mui-checked {
    transform: translateX(18px) !important;
    padding: 1px !important;
    width: 61% !important; /* Inner width of circle when enabled*/
    height: 100% !important;
  }
}

.cust-frame-parent-UF {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 3%;
  font-size: 18px;
  max-width: 90% !important;
}

.usbtn-gld {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  background-color: #013365 !important;
}

.search-icon-unsubscribed {
  padding: 2px 5px 2px 5px !important;
  border-radius: 5px !important;
  width: 55px !important;
  background-color: #013365 !important;
  margin-right: 35px !important;
}

.img-srch-unsubscribed {
  border-radius: 6px;
  width: 35px;
}
