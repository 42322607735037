.custom-colors .MuiSwitch-thumb {
  color: #e22c2c;
}
.custom-colors .MuiButtonBase-root.MuiSwitch-switchBase {
  background-color: transparent;
}
.custom-colors .Mui-checked + .MuiSwitch-track {
  background-color: transparent !important;
  border: 2px solid #2aca7d;
}
.custom-colors .MuiSwitch-track {
  background-color: transparent !important;
  border: 2px solid #e22c2c;
}

.custom-colors .Mui-checked .MuiSwitch-thumb {
  background-color: #2aca7d !important;
}

.switch-v2 {
  border: solid;
  border-color: #e22c2c;
}

.switch-v2 .MuiSwitch-thumb {
  color: #e22c2c;
}
.switch-v2 .MuiSwitch-track {
  background-color: white;
}

.switch-v2-checked {
  border: solid;
  border-color: #2aca7d;
}

.switch-v2-checked .Mui-checked .MuiSwitch-thumb {
  background-color: #2aca7d !important;
}
.switch-v2-checked .MuiSwitch-track {
  background-color: white !important;
}

.switch-v2-small {
  width: 29px !important;
  height: 10px !important;
}

.switch-v2-small .MuiSwitch-thumb {
  width: 7px !important;
  height: 7px !important;
}
.switch-v2-small .MuiSwitch-switchBase {
  transform: translateX(-5px) !important;
}
.switch-v2-small .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(5px) !important;
}

.MuiSwitch-root {
  padding: 0 !important;
  width: 42px !important; /* Width of Toggle button */
  height: 21px !important; /* Height of Toggle button */
  border-radius: 30px !important;
}

.MuiSwitch-root .Mui-checked + .MuiSwitch-track {
  opacity: 2 !important;
}

.MuiSwitch-root .MuiSwitch-switchBase {
  padding: 5px !important;
  width: 172% !important; /* Inner width of circle when disabled*/
  height: 100% !important;
  transform: translateX(-18px) !important;
  &.Mui-checked {
    transform: translateX(18px) !important;
    padding: 1px !important;
    width: 51% !important; /* Inner width of circle when enabled*/
    height: 100% !important;
  }
}

.mail-review-option-2-UF {
  position: relative;
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: left;
  font-size: 20px;
  color: #343434;
}

.table-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
  padding: 0 10px;
}

.table-pagination button {
  min-width: 27px;
  height: 28px;
}

.table-pagination ul button {
  border: 1px solid;
  border-radius: 3px;
  margin: 2px;
}

.asbtn-gld {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  background-color: #013365 !important;
}

.cust-frame-parent-AF {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 18px;
  margin-top: 3%;
  max-width: 90% !important;
}

.search-icon-automated {
  padding: 2px 5px 2px 5px !important;
  border-radius: 5px !important;
  width: 55px !important;
  background-color: #013365 !important;
  margin-right: 35px !important;
}

.img-srch-automated {
  border-radius: 6px;
  width: 35px;
}
