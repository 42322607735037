.app-header-top {
  width: 100% !important;
  height: "80px" !important;
  justify-content: space-between;
  /* padding-left: 2%; */
  /* padding-right: 5%; */
  box-sizing: border-box;
  background-color: #fff;
  /* box-shadow: 0px 2px 5px #dbdbdb; */
}

.app-bar-box {
  display: flex;
  text-align: center;
  justify-content: center;
}

.abc {
  width: 100%;
}

.app-bar-content1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-bar-content1 div {
  width: 8%;
}
.MuiPaper-root .app-bar-content1 .custom-divider {
  width: 15%;
  background-color: #6aaaea;
}

.sidebar-devider {
  border-color: #196ec3 !important;
}

.custom-main-heading {
  margin-left: 5% !important;
}

.logoRightHeader .logoRight {
  padding-right: 50px;
  cursor: pointer;
}
.logoRightHeader .logoRight img {
  max-height: 35px;
  margin: 0 10px;
}
.logoRightHeader {
  padding: 5px 0 0;
  display: flex;
}

.MuiButton-textPrimary.logoutBtn {
  font-weight: 800 !important;
  padding: 10px 0;
}

.custom-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 35%;
}

.custom-drower-header {
  display: flex !important;
  justify-content: right !important;
}

.drower-listItem {
  display: block !important;
  text-align: center !important;
}

.listItem-text {
  color: #0257ab !important;
  display: block !important;
  font-weight: bold !important;
}

.hoverTextColor:hover {
  background-color: #0257ab;
  color: white !important;
}

.custom-list
  > .MuiListItem-root
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root {
  font-weight: bold;
}

.custom-listItem-header {
  display: block;
}

.custom-listItem-header:hover {
  background-color: #0257ab;
  color: white;
}

.custom-img-icon {
  margin-bottom: 10px;
}

.css-184mzhy-MuiButtonBase-root-MuiIconButton-root {
  margin-left: 0 !important;
}

.drower-text {
  color: white;
  padding: 0 0 0 15px;
}

.drower-text-2 {
  color: white;
  padding: 0 0 0 20px;
}
.custom-main-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.custom-main-list > .custom-list2 {
  width: 100%;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #343434 !important;
  padding-right: 20px;
}

.css-1xektrd {
  color: #343434 !important;
  padding-right: 20px;
}

.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  color: #343434 !important;
  padding-left: 20px !important;
}

.css-1x52uzk {
  margin: 0 !important  ;
}
